
import { Component, Prop, Vue } from "vue-property-decorator";
import { deleteUserPhoto } from "./userPanelWebServices";
import store from "../store/index";
import VueUploadComponent from "vue-upload-component";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { getBaseImagesUrl, getBaseServiceUrl } from "../helpers/settingsHelper";
import { UserPhoto, getPhotos } from "@/serviceClients/userWebServices";
import { pushError } from "@/core";
import { AccountType } from "../register/registerModel";


@Component({
  components: {
    VueUploadComponent,
  },
})
export default class UploadPicture extends Vue {
  isLoading = false;
  edit = false;

  get editValue() {
    return this.edit;
  }

  photos: UserPhoto[] = [];

  files: VUFile[] = [];

  get hasAny() {
    return this.photos.length > 0;
  }

  get userPhotos() {
    return this.photos;
  }

  get userId() {
    return store.getters.module.user!.id;
  }

  get headers() {
    return {
      authorization: `Bearer ${store.getters.module.user!.token}`,
    };
  }

  get serviceUrl() {
    return getBaseServiceUrl();
  }

  get imagesUrl() {
    return getBaseImagesUrl();
  }

  get isAssistant() {
    return store.getters.module.user!.accountType == AccountType.Assistant;
  }

  cropper?: Cropper = undefined;

  editSave() {
    let oldFile = this.files[0];
    let binStr = atob(
      this.cropper!.getCroppedCanvas().toDataURL(oldFile.type).split(",")[1]
    );
    this.setEdit(false);
    let arr = new Uint8Array(binStr.length);
    for (let i = 0; i < binStr.length; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    let file = new File([arr], oldFile.name, { type: oldFile.type });
    (this.$refs.upload as any).update(oldFile.id, {
      file,
      type: file.type,
      size: file.size,
      active: true,
    });
  }

  inputFile(newFile: any, oldFile: any, prevent: () => any) {
    if (newFile && !oldFile) {
      this.$nextTick(() => {
        this.setEdit(true);
      });
    }
    if (!newFile && oldFile) {
      this.setEdit(false);
    }

    if(newFile?.error) {
      pushError(this.$t("Error uploading image. Please make sure that image contains your face. Pictures of children are forbidden."));
    }

    if (newFile?.success !== oldFile?.success) {
      this.fetch();
    }
  }

  setEdit(value: boolean) {
    this.edit = value;
    if (value) {
      this.$nextTick(() => {
        if (!this.$refs.editImage) {
          return;
        }
        let cropper = new Cropper(this.$refs.editImage as HTMLCanvasElement, {
          zoomable: false,
          viewMode: 1,
          background: false,
        });
        this.cropper = cropper;
      });
    } else {
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = undefined;
      }
    }
  }

  inputFilter(newFile: any, oldFile: any, prevent: () => any) {
    if (newFile && !oldFile) {
      if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
        pushError(this.$t("Your choice is not a picture"));
        return prevent();
      }
    }
    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
      newFile.url = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.url = URL.createObjectURL(newFile.file);
      }
    }
  }

  async deletePhoto(id: string) {
    this.isLoading = true;
    try {
      await deleteUserPhoto(store.getters.module.user!.id, id)
      if (store.getters.module.user!.avatarId === id) {
        await store.dispatch.module.setPhotoAsMain(undefined);
      }
      this.fetch();
    } catch (error) {
      pushError((error as any).response?.data?.error || error, { title: this.$t("An error occurred while deleting photo") });
    } finally {
      this.isLoading = false;
    }
  }

  async setPhotoAsMain(photoId: string) {
    await store.dispatch.module.setPhotoAsMain(photoId);
    this.fetch();
  }

  error() {}

  fetch() {
    this.isLoading = true;
    getPhotos(store.getters.module.user!.id)
      .then((response) => {
        this.photos = response.data;
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while fetching data") });
      });
  }

  mounted() {
    this.fetch();
  }
}
