
import Vue from "vue";
import Component from "vue-class-component";
import OfferList from "@/offers/OfferList.vue";
import OfferListMobile from "@/offers/OfferListMobile.vue";
import {
  getOfferList,
  JobOfferInterestStatus,
  JobOfferViewModel,
  markAsSeen,
} from "../offers/offerWebServices";
import store from "@/store";
import { AccountType } from "@/register/registerModel";
import { pushError } from "@/core";
import { CategoryGroup, fetchCategories } from "@/serviceClients/categoryWebServices";
import NoOffersFromClient from "@/offers/NoOffersFromClient.vue";
import OffersListClient from "@/offers/OfferListClient.vue";

@Component({
  components: {
    NoOffersFromClient,
    OfferList,
    OfferListMobile,
    OffersListClient
  },
})
export default class Offers extends Vue {
  offers: JobOfferViewModel[] = [];
  categories: CategoryGroup[] = [];
  isLoading = true;

  mounted() {
    store.commit.module.setActiveModuleName(this.title);

    getOfferList()
      .then((response) => {
        this.offers = response.data;
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while fetching data") });
      });

    fetchCategories()
      .then((response) => {
        this.categories = response.data;
      })
      .catch(() => { /* no-op*/ });
  }

  get hasCoins() {
    return (store.getters.module.balance || 0) > 0;
  }

  get hasUnseenAccepted() {
    return store.getters.jobOffers.unseenInterestAccepted > 0;
  }

  get hasUnseenDeclined() {
    return store.getters.jobOffers.unseenInterestDeclined > 0;
  }

  async onTabAcceptedClicked() {
    if (store.getters.jobOffers.unseenInterestAccepted > 0) {
      await markAsSeen(JobOfferInterestStatus.Accepted);
      store.dispatch.jobOffers.setUnseenInterest({
        status: JobOfferInterestStatus.Accepted,
        number: 0,
      }).catch(() => { /* noop */ });
    }
  }

  async onTabDeclinedClicked() {
    if (store.getters.jobOffers.unseenInterestDeclined > 0) {
      await markAsSeen(JobOfferInterestStatus.Declined);
      store.dispatch.jobOffers.setUnseenInterest({
        status: JobOfferInterestStatus.Declined,
        number: 0,
      }).catch(() => { /* noop */ });
    }
  }

  get isLoggedInAsClient() {
    return store.getters.module.user?.accountType === AccountType.Client;
  }

  get offersNew() {
    return this.offers.filter((o) => !o.interestStatus);
  }

  get offersInterested() {
    return this.offers.filter((o) => o.interestStatus === JobOfferInterestStatus.Sent);
  }

  get offersAccepted() {
    return this.offers.filter((o) => o.interestStatus === JobOfferInterestStatus.Accepted);
  }

  get offersDeclined() {
    return this.offers.filter((o) => o.interestStatus === JobOfferInterestStatus.Declined);
  }

  get title() {
    return this.isLoggedInAsClient
      ? this.$t("Your job offers")
      : this.$t("Active job offers");
  }
}
