
import { Component, Mixins } from "vue-property-decorator";
import OfferListItem from "./OfferListItem.vue";
import { getCategoryTree } from "@/helpers/categoryTreeMapper";
import CountrySelect from "@/components/CountrySelect.vue";
import Multiselect from 'vue-multiselect';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import OfferListBase from "./OfferListBase"

@Component({
  components: {
    OfferListItem,
    Treeselect,
    CountrySelect,
    Multiselect,
  },
})
export default class OfferList extends Mixins(OfferListBase) {

  mounted() {
    super.mounted()
  }

  get categoryOptions() {
    return getCategoryTree(this, this.categories);
  }

  get countryOptions() {
    return this.countries.map((c) => {
      return {
        label: c.text,
        id: c.value,
      };
    });
  }

  get regionOptions() {
    return this.regions.map((region) => {
      return {
        id: region.id,
        label: region.name,
      }
    })
  }

  cityOptions() {
    if (!this.citiesOptionsExist) {
      return [];
    }

    return this.regions.filter((region) => region.id === this.localFilters.regionId)[0].cities.map((city) => {
      return {
        id: city.id,
        label: city.name,
      }
    })
  }
}
