
import store from "@/store"
import { Vue, Prop, Component } from "vue-property-decorator"
import { removeAccount } from "./removeAccountWs"

@Component
export default class RemoveAccount extends Vue {

  text: string = ""
  textState: boolean | null = null

  isRemoving = false

  get userEmail() {
    return store.getters.module.user?.email ?? ''
  }

  onRemove() {
    if (this.text !== this.userEmail) {
      this.textState = false
      return
    }

    this.textState = true
    this.isRemoving = true

    removeAccount().then(() => {
      store.commit.module.setUser(undefined)
      store.dispatch.module.saveUserToStorage().catch()
      this.$router.push("/").catch()
    }).finally(() => { this.isRemoving = false }).catch()
  }
}
