
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";

import { fetchInvoiceDetails, setInvoiceDetails } from "./userPanelWebServices";
import { pushError } from "@/core";
@Component
export default class InvoiceDetailsForm extends Vue {
  @Prop() hasExternalSaveButton!: boolean;

  form: string = '';
  isLoading = false;

  mounted() {
    this.isLoading = true;
    fetchInvoiceDetails(store.getters.module.user!.id)
      .then((response) => {
        this.form = response.data;
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while fetching data") });
      });
  }

  onSubmit() {
    this.isLoading = true;
    return setInvoiceDetails(store.getters.module.user!.id, this.form)
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while saving data") });
      });
  }
}
