
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  fetchAddresses,
  updateAddress,
  createAddress,
  Address,
} from "./userPanelWebServices";
import store from "../store/index";
import CountrySelect from "../components/CountrySelect.vue";
import { getRegions, Region } from "../serviceClients/regionWebService";
import Multiselect from 'vue-multiselect';
import { geocodeAddress } from "@/helpers/geocodingHelper";
import { pushError } from "@/core";

@Component({
  components: {
    CountrySelect,
    Multiselect
  },
})
export default class EditAddress extends Vue {
  @Prop() hasExternalSaveButton!: boolean;
  isLoading = false;
  address: Partial<Address> = {
    city: null
  };
  alreadyHasAnAddress = false;
  regions: Region[] = [];
  selectedRegion: Region | null = null;

  get regionOptions() {
    return this.regions;
  }

  get cityOptions() {
    return this.selectedRegion?.cities ?
      this.selectedRegion.cities
       : [];
  }

  fetchRegions() {
    if(!this.address.country) {
      return Promise.resolve([]);
    }
    this.isLoading = true;
    return getRegions(this.address.country)
      .then((response) => {
        this.regions = response.data;
        return response.data;
      })
      .finally(() => {
        this.isLoading = false;
      })
  }

  mounted() {
    this.$watch(() => this.address.country, (current, old) => {
      if(old) {
        this.address.city = null;
        this.selectedRegion = null;
      }
      if(!this.address.country) {
        this.regions = [];
        return;
      }
      this.fetchRegions()
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
    });

    this.isLoading = true;
    fetchAddresses(store.getters.module.user!.id)
      .then((response) => {
        this.alreadyHasAnAddress = response.data.length > 0;
        if (this.alreadyHasAnAddress) {
          this.address = response.data[0];
          return this.fetchRegions()
            .then(() => {
              const userRegion = this.regions.find(r => r.id === this.address.city?.region?.id);
              if(userRegion) {
                this.selectedRegion = userRegion;
              }
            });
        }
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
  }

  isValid() {
    return this.address.city && this.address.country;
  }

  onSubmit() {
    this.isLoading = true;

    return geocodeAddress(this.address as Address).then((result) => {
        this.address.longitude = result.lng();
        this.address.latitude = result.lat();
        const promise = this.alreadyHasAnAddress
        ? updateAddress(store.getters.module.user!.id, this.address as Address)
        : createAddress(store.getters.module.user!.id, this.address as Address);
        return promise
          .catch((error) => {
            pushError(error.response?.data?.error || error);
          });
    })
    .finally(() => {
      this.isLoading = false;
    })
    .catch((error) => {
      pushError(error.response?.data?.error || error);
    });
  }
}
