import countries from "@/dictionaries/countries";
import { CategoryGroup } from "@/serviceClients/categoryWebServices";
import { getRegions, Region } from "@/serviceClients/regionWebService";
import { Component, Prop, Vue } from "vue-property-decorator"
import { JobOfferViewModel } from "./offerWebServices";

interface JobOffersListFilters
{
  phrase?: string;
  categories: string[];
  languages: string[];
  countryCode?: string;
  cityId?: string;
  regionId?: string;
}

@Component
export default class JobOfferListBase extends Vue {
  @Prop() offers!: JobOfferViewModel[]
  @Prop() categories!: CategoryGroup[]

  emptyFilters: JobOffersListFilters = {
    categories: [],
    languages: [],
  }

  isLoading = false
  localFilters: JobOffersListFilters = { ...this.emptyFilters }
  regions: Region[] = []
  countries: { text: string; value: string }[] = []

  mounted() {
    this.countries = countries;
  }

  fetchRegions() {
    if(!this.localFilters.countryCode) {
      return Promise.resolve([]);
    }
    return getRegions(this.localFilters.countryCode)
      .then((response) => {
        this.regions = response.data;
        return response.data;
      });
  }

  get offerList() {
    console.log(this.offers, 'offers')
    return this.offers
      .filter(o => {
        if(this.localFilters.countryCode && this.localFilters.countryCode !== o.countryCode)
          return false;
        if(this.localFilters.cityId && this.localFilters.cityId !== o.cityId)
          return false;
        if(this.localFilters.regionId && this.localFilters.regionId !== o.regionId)
          return false;
        if(this.localFilters.categories && this.localFilters.categories.length && !this.localFilters.categories.some(c => c === o.categoryId))
          return false;
        if(!!this.localFilters.phrase &&
            !o.description.toLowerCase().includes(this.localFilters.phrase.toLowerCase()) &&
            !o.headline.toLowerCase().includes(this.localFilters.phrase.toLowerCase())
          )
          return false;
        return true;
      })
      .sort((a, b) => new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime())
  }

  resetFilters() {
    this.localFilters = { ...this.emptyFilters }
  }

  get regionsOptionsExist(): boolean {
    return !!this.regions.length
  }

  get citiesOptionsExist(): boolean {
    if (!this.regionsOptionsExist) {
      return false;
    }

    const regions = this.regions.filter((region) => region.id === this.localFilters.regionId)

    return !!regions.length && !!regions[0].cities.length
  }

  countryChanged() {
    this.localFilters = Object.assign({}, this.localFilters, { cityId: undefined, regionId: undefined })

    if (!this.localFilters.countryCode) {
      this.regions = [];
      return;
    }

    this.fetchRegions().catch(() => { /* no-op */ });
  }

  regionChanged() {
    this.localFilters = Object.assign({}, this.localFilters, { cityId: undefined })
  }

  cityChanged() {
    this.$forceUpdate();
  }
}
