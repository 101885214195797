
import { getEducationLevelOptionTranslation } from "@/accountSetup/educationLevelHelper";
import { getLevelOptionTranslation } from "@/accountSetup/languageLevelHelper";
import { UserRatingInfo } from "@/assistants/assistantsWebServices";
import { getLanguage } from "@/core/translate";
import countries from "@/dictionaries/countries";
import { getBaseImagesUrl } from "@/helpers/settingsHelper";
import { LoggedUserInformation } from "@/login/loginModel";
import { AccountType } from "@/register/registerModel";
import { getMyRating } from "@/serviceClients/userWebServices";
import store from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";
import RatingStars from "../assistants/RatingStars.vue";
import RemoveAccount from "./RemoveAccount.vue";
import languages from "@/core/translate/translations/languageCodesTranslations/languages.json";

@Component({
  components: {
    RatingStars,
    RemoveAccount,
  },
})
export default class PanelSummary extends Vue {
  user: LoggedUserInformation = {} as LoggedUserInformation;

  rating: UserRatingInfo = {
    averageRating: 0,
    totalRatings: 0,
  } as UserRatingInfo;
  name = "";
  isAssistant = false;
  languagesText = "";
  location = "";
  userSince = "";
  education = "";

  async mounted() {
    this.user = store.getters.module.user!;

    this.name = `${
      this.user.firstName
    } ${this.user.lastName[0].toUpperCase()}.`;
    this.isAssistant =
      store.getters.module.user?.accountType === AccountType.Assistant;
    this.setLanguages();
    await this.setRating();
    this.setLocation();
    this.setUserSince();
    this.setEducation();
  }

  private setEducation() {
    const educationParts: string[] = [];

    if (this.user.educationLevel) {
      educationParts.push(
        getEducationLevelOptionTranslation(
          this,
          this.user.educationLevel
        ).toString()
      );
    }

    if (this.user.educationDetails) {
      educationParts.push(this.user.educationDetails);
    }

    this.education = educationParts.join(", ");
  }

  private setUserSince() {
    const date = new Date(this.user.registrationDateTime);
    this.userSince = `${this.$t("User since")} ${date.toLocaleDateString(
      "pl"
    )}`;
  }

  private setLocation() {
    const locationParts: string[] = [];

    if (this.user.address?.country) {
      locationParts.push(
        countries.find((c) => c.value === this.user.address!.country)!.text
      );
    }

    if (this.user.address?.city) {
      locationParts.push(this.user.address?.city.name);
    }

    this.location = locationParts.join(", ");
  }

  get avatarUrl() {
    return store.getters.module.user!.avatarId
      ? `${getBaseImagesUrl()}${store.getters.module.user!.avatarId || "default"}.jpg`
      : "";
  }

  private setLanguages() {
    this.languagesText = this.user.languages
      .map((x) => {
        const lang = languages.find((l) => l.code === x.languageCode)!;
        const langLvl = getLevelOptionTranslation(this, x.level).toString();
        return `${lang[getLanguage()]} ${langLvl}`;
      })
      .join(", ");
  }

  private async setRating(): Promise<void> {
    const response = await getMyRating();
    if (response.data) {
      this.rating = response.data;
    }
  }
}
