
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  JobOfferViewModel,
  deleteOffer
} from "./offerWebServices";
import countries from "@/dictionaries/countries";
import { pushError } from "../core";

@Component
export default class OfferListItemClient extends Vue {
  @Prop() offer!: JobOfferViewModel;

  status = this.offer.interestStatus;

  get validFor() {
    const daysToExpire = Math.ceil((new Date(this.offer.expirationDate).getTime() - new Date().getTime())/1000/3600/24);

    if (daysToExpire <= 0) {
      return this.$t("Offer expired");
    }

    if (daysToExpire === 1) {
      return this.$t("Valid only for today")
    }

    return this.$t("Valid for {0} days", [daysToExpire])
  }

  removeOffer () {
    this.$bvModal
      .msgBoxConfirm(
        this.$t("Are you sure that you want to delete {0} job offer?", [
          this.offer.headline,
        ]).toString(),
        {
          title: this.$t("Delete job offer").toString(),
          okTitle: this.$t("Delete").toString(),
          cancelTitle: this.$t("Cancel").toString(),
        }
      )
      .then((value) => {
        if (value) {
          deleteOffer(this.offer.id).then(() => {
            this.$toasted.success(
              this.$t("Job offer has been deleted").toString(),
              {
                duration: 2000,
              }
            );

            window.location.reload(true);
          })
            .catch((error) => {
              pushError(error.response?.data?.error || error);
            });
        }
      }).catch(() => { /* noop */ });
  }
}
