
import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";
import {
  getAssistantInformation,
  AssistantInfo,
} from "../assistants/assistantsWebServices";
import { pushError } from "@/core";
import {
  EducationLevel,
  Gender,
  MaritalStatus,
} from "@/accountSetup/accountSetupService";
import {
  getHeightInOtherScale,
  HeightScale,
} from "../accountSetup/assistantHeightHelper";
import { getMaritalStatusOptionsTranslations } from "@/accountSetup/maritalStatusHelper";
import { AccountType } from "@/register/registerModel";
import UploadPicture from "./UploadPicture.vue";
import SelectHeight from "../assistant/SelectHeight.vue";
import HourlyRate from "../assistant/HourlyRate.vue";
import BirthDatepicker from "vue-birth-datepicker/src/birth-datepicker";
import { updateBasicInfo } from "./userPanelWebServices";
import { getEducationLevelOptionsTranslations } from "@/accountSetup/educationLevelHelper";

type Form = {
  firstName: string;
  lastName: string;
  gender?: Gender;
  maritalStatus?: MaritalStatus;
  birthDate?: number;
  height?: number;
  description?: string;
  educationLevel?: EducationLevel;
  educationDetails?: string;
};

@Component({
  components: {
    BirthDatepicker,
    SelectHeight,
    UploadPicture,
    HourlyRate,
  },
})
export default class BasicInformation extends Vue {
  user: Form = {} as Form;

  isLoadingFirstForm = false;
  isLoadingSecondForm = false;

  rawHeight: { value?: number; scale: HeightScale } = { scale: "cm" };

  get isAssistant(): boolean {
    return store.getters.module.user!.accountType === AccountType.Assistant;
  }

  get higherEducationLevel() {
    return EducationLevel.Higher;
  }
  get studentEducationLevel() {
    return EducationLevel.Student;
  }
  get otherEducationLevel() {
    return EducationLevel.Other;
  }

  async mounted() {
    this.isLoadingFirstForm = true;
    this.isLoadingSecondForm = true;
    try {
      const response = await getAssistantInformation(
        store.getters.module.user!.id
      );
      this.user = {
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        height: response.data.height,
        maritalStatus: response.data.maritalStatus,
        description: response.data.description,
        birthDate: response.data.birthDate
          ? new Date(response.data.birthDate).getTime()
          : undefined,
        gender: response.data.gender,
        educationLevel: response.data.educationLevel,
        educationDetails: response.data.educationDetails,
      };
      this.rawHeight = { value: this.user.height, scale: "cm" };
    } catch (error) {
      pushError((error as any).response?.data?.error || error);
    } finally {
      this.isLoadingFirstForm = false;
      this.isLoadingSecondForm = false;
    }
  }

  async onUpdateSecondForm() {
    this.isLoadingSecondForm = true;
    try {
      console.log(this.user.birthDate);
      await updateBasicInfo(store.getters.module.user!.id, {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        gender: this.user.gender!,
        description: this.user.description ?? "",
        height: !!this.rawHeight.value
          ? getHeightInOtherScale(
              this.rawHeight.value,
              this.rawHeight.scale,
              "cm"
            )
          : undefined,
        maritalStatus: this.user.maritalStatus,
        birthDate: this.user.birthDate
          ? new Date(this.user.birthDate)
          : undefined,
        educationLevel: this.user.educationLevel,
        educationDetails: this.user.educationDetails,
      });
      await store.dispatch.module.updateStoredUser({
        ...this.user,
        birthDate: this.user.birthDate
          ? new Date(this.user.birthDate).toISOString()
          : undefined,
      });
    } catch (error) {
      if (
        (error as any).response?.data?.error == "ContainsContactInformation"
      ) {
        pushError(this.$t("Description cannot contain contact information."));
      } else {
        pushError((error as any).response?.data?.error || error, {
          title: this.$t("An error occurred while saving data"),
        });
      }
    } finally {
      this.isLoadingSecondForm = false;
    }
  }

  get genderOptions() {
    return [
      { text: this.$t("Female"), value: Gender.Female },
      { text: this.$t("Male"), value: Gender.Male },
      { text: this.$t("Do not set"), value: null },
    ];
  }

  get educationLevelOptions() {
    return getEducationLevelOptionsTranslations(this)
  }

  get maritalStatusOptions() {
    return getMaritalStatusOptionsTranslations(this);
  }
}
