
import { getBaseImagesUrl } from "@/helpers/settingsHelper";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { pushError } from "../../core";
import {
  acceptOfferInterest,
  declineOfferInterest,
  JobOfferInterestModel,
  JobOfferInterestStatus,
} from "../offerWebServices";

@Component
export default class OfferInterestsListItem extends Vue {
  @Prop() interest!: JobOfferInterestModel;
  offerId = this.$route.params.offerId;

  fullDescriptionShown = false;
  hideReadMoreButton = false;
  watchedInterest = {} as JobOfferInterestModel;

  mounted() {
    this.watchedInterest = { ...this.interest };
  }

  toggleFullDescription() {
    this.fullDescriptionShown = !this.fullDescriptionShown;
  }

  get fullDescription(): string {
    return this.watchedInterest.userDescription;
  }

  get partDescription(): any {
    const text = this.watchedInterest.userDescription;

    if (!text){
      return ''
    }

    if (text.length <= 100) {
      this.hideReadMoreButton = true
    }

    return text.length > 100 ? `${text.slice(0, 150)}...` : text;
  }

  get imagesUrl() {
    return getBaseImagesUrl();
  }

  get isDeclined() {
    return this.watchedInterest.status === JobOfferInterestStatus.Declined;
  }

  get isAccepted() {
    return this.watchedInterest.status === JobOfferInterestStatus.Accepted;
  }

  onSendAcceptClicked() {
    this.watchedInterest.status = JobOfferInterestStatus.Accepted;
    acceptOfferInterest(this.offerId, this.interest.id)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }

  onSendDeclineClicked() {
    this.watchedInterest.status = JobOfferInterestStatus.Declined;
    declineOfferInterest(this.offerId, this.interest.id)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }
}
