
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  addOffer,
  deleteOffer,
  editOffer,
  getOffer,
  JobOfferSaveRequest,
  JobOfferViewModel,
} from "./offerWebServices";
import store from "../store";
import LanguagesDropdown from "vue-languages-dropdown";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  CategoryGroup,
  fetchCategories,
} from "../serviceClients/categoryWebServices";
import { getCategoryTree } from "@/helpers/categoryTreeMapper";
import { AccountType } from "@/register/registerModel";
import CountrySelect from "../components/CountrySelect.vue";
import { getRegions, Region, City } from "../serviceClients/regionWebService";
import Multiselect from 'vue-multiselect';
import { pushError } from "@/core";
import VueI18n from "vue-i18n";

@Component({
  components: {
    LanguagesDropdown,
    Treeselect,
    CountrySelect,
    Multiselect
  },
})
export default class OfferEditor extends Vue {
  @Prop() title!: string;
  @Prop() existingOffer?: JobOfferViewModel;

  offerId: string | null = null;
  offer: JobOfferSaveRequest = {
    language: store.getters.module.user!.language,
    countryCode: null,
    regionId: null
  } as JobOfferSaveRequest;
  isLoading = true;
  categories: CategoryGroup[] = [];
  regions: Region[] = [];
  selectedRegion: Region | null = null;
  selectedCity: City | null = null;

  get breadcrumbsList() {
    return  [
      {
        text: this.$t('Home'),
        href: '/'
      },
      {
        text: this.$t('Your job offers'),
        href: '/offers'
      },

      {
        text: this.$route.name === 'New offer'? this.$t('Add job offer'): this.$t('Edit job offer'),
        href: '#'
      }
    ]
  }

  get cityOptions() {
    return this.selectedRegion?.cities ?
      this.selectedRegion.cities
       : [];
  }

  get buttonSaveText(): VueI18n.TranslateResult {
    return this.isAddNewOfferView ? this.$t('Add offer'): this.$t('Save')
  }
  get isAddNewOfferView(): boolean {
    return this.$route.name === 'New offer'
  }

  get regionOptions() {
    return this.regions;
  }

  fetchRegions() {
    if(!this.offer.countryCode) {
      return Promise.resolve([]);
    }
    this.isLoading = true;
    return getRegions(this.offer.countryCode)
      .then((response) => {
        this.regions = response.data;
        return response.data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  get categoryOptions() {
    return getCategoryTree(this, this.categories);
  }

  languageSelected(value: { code: string }) {
    this.offer.language = value.code;
  }

  get isLoggedInAsClient() {
    return store.getters.module.user?.accountType === AccountType.Client;
  }

  mounted() {
    if (!this.isLoggedInAsClient) {
      this.goBackToList();
    }

    this.$watch(() => this.offer.countryCode, (current, old) => {
      if(old) {
        this.offer.cityId = null;
        this.selectedCity = null;
        this.offer.regionId = null;
        this.selectedRegion = null;
      }
      if(!this.offer.countryCode) {
        this.regions = [];
        return;
      }
      this.fetchRegions()
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
    });

    this.$watch(() => this.selectedRegion, (current, old) => {
      if(old) {
        this.selectedCity = null;
        this.offer.cityId = null;
      }
    });

    this.offerId = this.$route.params.id;
    if (!!this.offerId) {
      store.commit.module.setActiveModuleName(this.$t("Edit job offer"));

      getOffer(this.$route.params.id)
        .then((response) => {
          if (!response.data || !response.data.id) {
            this.goBackToList();
          }
          this.offer = response.data;
          return this.fetchRegions()
            .then(() => {
              const userRegion = this.regions.find(r => r.id === this.offer.regionId);
              if(userRegion) {
                this.selectedRegion = userRegion;
              }
              const userCity = this.cityOptions.find(c => c.id === this.offer.cityId);
              if(userCity) {
                this.selectedCity = userCity;
              }
            });
        })
        .catch(() => {
          this.goBackToList();
        });
    } else {
      store.commit.module.setActiveModuleName(this.$t("Add job offer"));
    }

    fetchCategories()
      .then((response) => {
        this.categories = response.data;
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }

  onSubmit() {
    this.isLoading = true;
    this.offer.regionId = this.selectedRegion ? this.selectedRegion.id : null;
    this.offer.cityId = this.selectedCity ? this.selectedCity.id : null;
    const promise = this.offerId ? this.editOffer() : this.addOffer();
    promise
      .catch((error) => {
        pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while saving data") });
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }

  addOffer() {
    return addOffer(this.offer).then(() => {
      this.$toasted.success(this.$t("Job offer has been posted").toString(), {
        duration: 2000,
      });
      this.goBackToList();
    });
  }

  editOffer() {
    return editOffer(this.offerId!, this.offer).then(() => {
      this.$toasted.success(this.$t("Job offer has been saved").toString(), {
        duration: 2000,
      });
      this.goBackToList();
    });
  }

  onDelete() {
    this.$bvModal
      .msgBoxConfirm(
        this.$t("Are you sure that you want to delete {0} job offer?", [
          this.offer.headline,
        ]).toString(),
        {
          title: this.$t("Delete job offer").toString(),
          okTitle: this.$t("Delete").toString(),
          cancelTitle: this.$t("Cancel").toString(),
        }
      )
      .then((value) => {
        if (value) {
          deleteOffer(this.offerId!).then(() => {
            this.$toasted.success(
              this.$t("Job offer has been deleted").toString(),
              {
                duration: 2000,
              }
            );
            this.goBackToList();
          })
          .catch((error) => {
              pushError(error.response?.data?.error || error);
          });
        }
      }).catch(() => { /* noop */ });
  }

  goBackToList() {
    this.$router.push("/offers")
        .catch(() => { /* noop */ });
  }
}
