
import { Component, Mixins, Prop } from "vue-property-decorator";
import OfferListItem from "./OfferListItem.vue";
import { getCategoryTree } from "@/helpers/categoryTreeMapper";
import OfferListBase from './OfferListBase'

@Component({
  components: {
    OfferListItem,
  }
})
export default class OfferListMobile extends Mixins(OfferListBase) {
  @Prop() tabNumber?: number;

  selectedCategoryGroup: any | null = null;

  mounted() {
    super.mounted()
  }

  get tabNumberValue() {
    return !!this.tabNumber ? this.tabNumber : 0;
  }

  get selectedCategoryGroupName() {
    return this.selectedCategoryGroup?.label ?? '';
  }

  getCategoryGroupButtonText(categoryGroup: any) {
    const allCategoryIdsInGroup: string[] = categoryGroup.options.flatMap((c: any) => c.value)
    const selectedCategoriesInGroupCount = this.localFilters.categories.filter((id) => allCategoryIdsInGroup.includes(id)).length
    return !selectedCategoriesInGroupCount
      ? categoryGroup.label.toUpperCase()
      : `${categoryGroup.label.toUpperCase()} (${selectedCategoriesInGroupCount})`
  }

  get categoryOptions() {
    const jobsCategoryIds = this.offers.map((o) => o.categoryId)
    return getCategoryTree(this, this.categories).map((cat) => {
      return {
        label: cat.label,
        options: cat.children.map((x) => { return {
          id: x.id,
          label: x.label,
          options: x.children.filter((c) => jobsCategoryIds.includes(c.id)).map((opt) => { return {
            text: opt.label,
            value: opt.id,
          }})
        }}).filter((c) => !!c.options.length)
      }
    })
  }

  get countryOptions() {
    return this.countries;
  }

  get regionOptions() {
    return this.regions.map((region) => {
      return {
        value: region.id,
        text: region.name,
      };
    });
  }

  cityOptions() {
    if (!this.citiesOptionsExist) {
      return [];
    }

    return this.regions
      .filter((region) => region.id === this.localFilters.regionId)[0]
      .cities.map((city) => {
        return {
          value: city.id,
          text: city.name,
        };
      });
  }

  clearRegion() {
    this.localFilters = Object.assign({}, this.localFilters, { regionId: undefined })
  }

  clearCity() {
    this.localFilters = Object.assign({}, this.localFilters, { cityId: undefined })
  }
}
