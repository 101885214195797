
import store from "@/store";
import { Component, Vue } from "vue-property-decorator";
import PanelSummary from "./PanelSummary.vue";
import BasicInformation from "./BasicInformation.vue";
import EditAddressForm from "./EditAddressForm.vue";
import InvoiceDetailsForm from "./InvoiceDetailsForm.vue";
import Languages from "@/assistant/Languages.vue";
import SelectCategories from "@/accountSetup/steps/SelectCategories.vue";
import Interests from "@/assistant/Interests.vue";
import Hobbies from "@/assistant/Hobbies.vue";
import { AccountType } from "@/register/registerModel";

@Component({
  components: {
    PanelSummary,
    BasicInformation,
    EditAddressForm,
    InvoiceDetailsForm,
    Languages,
    Interests,
    Hobbies,
    SelectCategories,
  },
})
export default class UserPanelRoot extends Vue {
  get isUserLoggedIn(): boolean {
      return !!store.getters.module.user;
  }

  get isClient(): boolean {
    return store.getters.module.user!.accountType === AccountType.Client;
  }
}
