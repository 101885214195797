
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  JobOfferInterestStatus,
  JobOfferViewModel,
  sendOfferInterest,
} from "./offerWebServices";
import { getBaseImagesUrl } from "../helpers/settingsHelper";
import store from "../store";
import { AccountType } from "../register/registerModel";
import countries from "@/dictionaries/countries";
import { pushError } from "../core";

@Component
export default class OfferListItem extends Vue {
  @Prop() offer!: JobOfferViewModel;

  status = this.offer.interestStatus;
  showFullDescription = false;

  get imagesUrl() {
    return getBaseImagesUrl();
  }

  get description() {
    return this.offer.description.replace(/(?:\r\n|\r|\n)/g, "<br/>");
  }

  get validFor() {
    const daysToExpire = Math.ceil((new Date(this.offer.expirationDate).getTime() - new Date().getTime())/1000/3600/24);

    if (daysToExpire <= 0) {
      return this.$t("Offer expired");
    }

    if (daysToExpire === 1) {
      return this.$t("Valid only for today")
    }

    return this.$t("Valid for {0} days", [daysToExpire])
  }

  get truncatedDescription() {
    const text = this.description;
    var textToReturn = text.split("<br/>")[0];
    const maxCharacters = 50;
    textToReturn = textToReturn.substring(0, maxCharacters);
    if (textToReturn != text) {
      textToReturn += "...";
    }
    return textToReturn;
  }

  get descriptionNeedsTruncating() {
    return this.truncatedDescription != this.offer.description;
  }

  onSendYourInterestClicked() {
    sendOfferInterest(this.offer.id, store.getters.module.user!.id)
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
    this.status = JobOfferInterestStatus.Sent;
  }

  get isAccepted() {
    return this.status === JobOfferInterestStatus.Accepted;
  }

  get isSent() {
    return this.status === JobOfferInterestStatus.Sent;
  }

  get isClient() {
    return store.getters.module.user?.accountType === AccountType.Client;
  }

  get isOwnOffer() {
    return this.isClient && store.getters.module.user?.id === this.offer.userId;
  }

  get isAssistant() {
    return store.getters.module.user?.accountType === AccountType.Assistant;
  }

  get location() {
    let result = "";
    if(this.offer.countryCode) {
      const country = countries.find(c => c.value === this.offer.countryCode);
      result += country?.text;
    }
    if(this.offer.region) {
      result += " - " + this.offer.region.name;
    }
    if(this.offer.city) {
      result += " - " + this.offer.city.name;
    }
    if(!result) {
      return this.$t("All over the world");
    }
    return result;
  }
}
