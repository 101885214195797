
import { Component, Prop, Vue } from "vue-property-decorator";
import LoginModel, { LoggedUserInformation } from "./loginModel";
import callLogin from "./callLogin";
import { AxiosResponse } from "axios";
import store from "../store/index";
import LoginWithFacebook from "./LoginWithFacebook.vue";
import { setUserLanguage } from "@/core/translate";
import { pushError } from "@/core";

@Component({
  components: {
    LoginWithFacebook,
  },
})
export default class LoginForm extends Vue {
  form: Partial<LoginModel> = {};
  isLoading = false;

  onSubmit() {
    this.isLoading = true;
    callLogin(this.form as LoginModel)
      .then((response: AxiosResponse<LoggedUserInformation>) => {
        store.commit.module.setUser(response.data);
        store.dispatch.module.saveUserToStorage().catch(() => { /* noop */ });
        return setUserLanguage(response.data.language).then(() => {
          this.$toasted.success(
            `${this.$t('Welcome')}${
              !!store.getters.module.user?.firstName
                ? ", " + response.data.firstName
                : ""
            }!`,
            {
              duration: 2000,
            }
          );
          if (store.getters.module.urlToRedirectAfterLogon) {
            this.$router.push(store.getters.module.urlToRedirectAfterLogon).catch(() => { /* noop */ });
          } else {
            this.$router.push("/").catch(() => { /* noop */ });
          }
        });
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.error || error;
        const opts = { title: this.$t("An error occurred while logging in")};
        if (errorMessage === "InvalidCredentials") {
          pushError(this.$t("Provided email or password are invalid"), opts);
        } else if (errorMessage === "RegistrationNotConfirmed") {
          pushError(this.$t("Registration has not been confirmed yet. Please check your mailbox and follow the instructions in the received mail."), opts);
        } else {
          pushError(errorMessage);
        }
      });
  }
}
