
import { Component, Prop, Vue } from "vue-property-decorator";
import { JobOfferInterestModel } from "../offerWebServices";
import OfferInterestsListItem from "./OfferInterestsListItem.vue";

@Component({
    components:{
        OfferInterestsListItem,
    }
})
export default class OfferInterestsList extends Vue {
  @Prop() interests!: JobOfferInterestModel[];
  @Prop() informationVisible!: boolean;
}
