
import { Component, Mixins } from "vue-property-decorator";
import { getCategoryTree } from "@/helpers/categoryTreeMapper";
import CountrySelect from "@/components/CountrySelect.vue";
import Multiselect from 'vue-multiselect';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import OfferListBase from "./OfferListBase"
import OfferListItemClient from "@/offers/OfferListItemClient.vue";

@Component({
  components: {
    OfferListItemClient,
    Treeselect,
    CountrySelect,
    Multiselect,
  },
})
export default class OffersListClient extends Mixins(OfferListBase) {
  mounted() {
  }

  get categoryOptions() {
    return getCategoryTree(this, this.categories);
  }

  get countryOptions() {
    return this.countries.map((c) => ({ label: c.text, id: c.value }));
  }

  get regionOptions() {
    return this.regions.map((region) => ({ id: region.id, label: region.name })
    )
  }

  cityOptions() {
    if (!this.citiesOptionsExist) {
      return [];
    }

    return this.regions.filter((region) => region.id === this.localFilters.regionId)[0].cities.map((city) => {
      return {
        id: city.id,
        label: city.name,
      }
    })
  }
}
