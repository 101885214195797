
import { Component, Prop, Vue } from 'vue-property-decorator';

import * as FB from "fb";
import { getBaseUrl, getFacebookClientId } from '../helpers/settingsHelper';

@Component({
  components: {
  }
})
export default class LoginWithFacebook extends Vue {
  @Prop() canGo!: boolean;

  loginWithFacebook() {
    const loginUrl = FB.getLoginUrl({
      client_id: getFacebookClientId(),
      scope: 'email',
      response_type: 'token',
      redirect_uri: `${getBaseUrl()}/facebook`
    });
    window.location = loginUrl;
  }
}
