
import store from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { pushError } from "../../core";
import {
  getOfferInterests,
  JobOfferInterestsModel,
  JobOfferInterestStatus,
} from "../offerWebServices";
import OfferInterestsList from "./OfferInterestsList.vue";

@Component({
  components: {
    OfferInterestsList,
  },
})
export default class OfferInterests extends Vue {
  id: string = this.$route.params.offerId;
  isLoading: boolean = true;
  interestsModel!: JobOfferInterestsModel;

  mounted() {
    store.commit.module.setActiveModuleName(this.$t('Interested assistants'));

    getOfferInterests(this.id)
      .then((response) => {
        this.interestsModel = response.data;
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((error) => {
          pushError(error.response?.data?.error || error);
      });
  }

  get newInterests() {
    return this.interestsModel.interests.filter(
      (i) => i.status === JobOfferInterestStatus.Sent
    );
  }

  get acceptedInterests() {
    return this.interestsModel.interests.filter(
      (i) => i.status === JobOfferInterestStatus.Accepted
    );
  }

  get declinedInterests() {
    return this.interestsModel.interests.filter(
      (i) => i.status === JobOfferInterestStatus.Declined
    );
  }
}
