
import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountType } from "@/register/registerModel";
import store from "@/store/index";
import { CustomerInvoicingMode } from "../login/loginModel";
import { saveCustomerInvoicingMode } from "../accountSetup/accountSetupService";

@Component({
  components: { },
})
export default class SelectAccountStep extends Vue {
  @Prop() value!: AccountType;

  get localState() {
    return this.value;
  }

  set localState(value: any) {
    this.$emit("input", value);
  }

  save(): Promise<any> {
    return saveCustomerInvoicingMode(store.getters.module.user!.id, this.localState)
            .then(() => {
              store.dispatch.module.updateStoredUser({
                customerInvoicingMode: this.localState,
              }).catch(() => { /* noop */ });
            });
  }

  shouldBeDisplayed(): boolean {
    return store.getters.module.user?.accountType === AccountType.Unknown;
  }
  
  get zeroVatBgVariant() {
    return this.localState === CustomerInvoicingMode.ZeroVat ? "primary" : "";
  }

  get zeroVatTextVariant() {
    return this.localState === CustomerInvoicingMode.ZeroVat ? "white" : "";
  }

  get fullVatBgVariant() {
    return this.localState === CustomerInvoicingMode.FullVat ? "primary" : "";
  }

  get fullVatTextVariant() {
    return this.localState === CustomerInvoicingMode.FullVat ? "white" : "";
  }

  onZeroVatClicked() {
    this.localState = CustomerInvoicingMode.ZeroVat;
  }

  onFullVatClicked() {
    this.localState = CustomerInvoicingMode.FullVat;
  }
}
